<template>
  <base-view id="employer-training">
    <view-header title="Client Training" image="bg-ichra.jpg"
      >Here you can access training for Brick, FLEX and COBRA Systems utilized
      by Rocky Mountain Reserve. These training are designed to help you as the
      client contact become more enabled in the administrative role for your
      company benefits.
    </view-header>
    <view-section>
      <div
        class="flex flex-col items-stretch items-center justify-center md:flex-row"
      >
        <router-link
          to="/employer-training/cobra"
          class="flex flex-col px-6 py-8 mt-0 mb-8 text-center rounded-md shadow-md bg-neutral-lighter md:mx-8 hover:bg-neutral-light active:bg-neutral"
        >
          <h1 class="pb-4 text-3xl font-semibold border-b">COBRA System</h1>
          <div class="flex-1 pt-12 pb-4 md:mx-8">
            <div class="p-4">
              <img
                src="@/assets/cobrapoint.png"
                alt=""
                class="mx-auto max-h-12"
              />
            </div>
          </div>
        </router-link>
        <router-link
          to="/employer-training/alegeus"
          class="flex flex-col px-6 py-8 mt-0 mb-8 text-center rounded-md shadow-md bg-neutral-lighter md:mx-8 hover:bg-neutral-light active:bg-neutral"
        >
          <h1 class="pb-4 text-3xl font-semibold border-b">Flex System</h1>
          <div class="flex-1 pt-12 pb-4 md:mx-8">
            <div class="p-4">
              <img src="@/assets/alegeus.png" alt="" class="mx-auto max-h-12" />
            </div>
          </div>
        </router-link>
        <router-link
          to="/employer-training/elevate"
          class="flex flex-col px-6 py-8 mt-0 mb-8 text-center rounded-md shadow-md bg-neutral-lighter md:mx-8 hover:bg-neutral-light active:bg-neutral"
        >
          <h1 class="pb-4 text-3xl font-semibold border-b">Elevate System</h1>
          <div class="flex-1 pt-12 pb-4 md:mx-8">
            <div class="p-4 bg-blue-elevate rounded">
              <img
                src="@/assets/elevateLogo.png"
                alt=""
                class="mx-auto max-h-12"
              />
            </div>
          </div>
        </router-link>
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewHeader from "@/components/layout/ViewHeader.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

export default {
  name: "EmployerTraining",
  components: {
    BaseView,
    ViewHeader,
    ViewSection
  },
  data() {
    return {
      links: ["How Does It Work?", "What Are The Benefits?"]
    };
  }
};
</script>

<style scoped>
.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
}

.wrap .link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  height: 15rem;

  border: 1px solid gainsboro;
  border-radius: 0.25rem;
  margin-top: 15px;
  padding: 1rem;
  transition: all 0.15s ease-in-out;
}

.link:hover {
  background-color: whitesmoke;
}

.link:active {
  background-color: gainsboro;
}

.link h1 {
  margin-bottom: 0;
}

.myImg {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.myImg img {
  max-width: 15rem;
}

@media only screen and (min-width: 1000px) {
  .wrap {
    flex-direction: row;
  }

  .wrap .link {
    width: auto;
    padding: 2rem 5rem;
    height: 20rem;
  }
}</style
>>
